"use client";

import { FC, memo } from "react";

import {
  Color,
  colorClassMap,
} from "apps/website/components/base/Text/Text.map";

import * as IconMap from "./Icon.map";

export interface IIcon {
  size?: IconMap.Size;
  color?: Color;
  icon: IconMap.IconSvg;
  className?: string;
}

const Icon: FC<IIcon> = ({ size = "default", color = "default", icon, className }) => {

  const computedClasses = `${IconMap.iconSizeClassMap[size]} ${colorClassMap[color]} ${className}`;
  const IconComponent = IconMap.iconSvgMap[icon];
  return (
    <div data-component="Icon" className={computedClasses}>
      { /** @ts-ignore */ }
      <IconComponent />
    </div>
  );
};

export default memo(Icon);
