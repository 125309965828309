export const availableTags = [ "div", "ul", "ol", "article" ] as const;

export const gridGapsMap = {
  default: "gap-4",
  lg: "gap-6 lg:gap-8",
  sm: "gap-2",
  none: "gap-0",
};

export type AvailableTag = typeof availableTags[number];
export type Gap = keyof typeof gridGapsMap;
